import $ from 'jquery';
import swal from 'sweetalert2';
import datepickerFactory from 'jquery-datepicker';
require('jquery-ui/themes/base/datepicker.css');
import "jquery-ui/ui/widgets/datepicker";

//import 'CKEDITOR';

var MOBILE_WIDTH = 768;
function isMobile(){
  var is =  ($(window).width()<=MOBILE_WIDTH);
  return is;
}

function readFlashMessage(){

  var  showFlashMsg = function (msg, typeMsg){
    swal.fire({title: msg, icon: typeMsg, showConfirmButton: false, timer: 5000000, 'showClass': 'animated tada', 'hideClass': 'animated tada',
    'onAfterClose': readFlashMessage
  });
};

if(flashMessage && flashMessage.length>0){
  var msg = flashMessage.shift();
  showFlashMsg(msg,'success');
  return;
}

if(flashMessageInfo && flashMessageInfo.length>0){
  var msg = flashMessageInfo.shift();
  showFlashMsg(msg,'info');
  return;
}

if(flashMessageError && flashMessageError.length>0){
  var msg = flashMessageError.shift();
  showFlashMsg(msg,'error');
  return;
}
}

function bindCountTextareaLimit(){
  if($('textarea[maxlength]').length>0){
    $('textarea[maxlength]').each(function(){
      var id = $(this).attr('id');
      var min = $(this).val().length;
      var max = $(this).attr('maxlength');
      var labelHtml = $('label[for="'+id+'"]').html();

      $('label[for="'+id+'"]').html(labelHtml + ' <span class="count">('+min+'/'+max+')</span>');

      $(this).bind('keyup',function(){
        var min = $(this).val().length;
        var max = $(this).attr('maxlength');
        //var labelHtml = $('label[for="'+id+'"]').html();
        $('label[for="'+id+'"] .count').html(' ('+min+'/'+max+')');
      });
    })
  }
}


function fixIssueOnInputs() {
  fixIssueOnDateInputs();
  fixIssueOnFileInputs();catchDateFormatOnSubmit();
}

function fixIssueOnDateInputs() {

  if ( $('input[type="date"]').length>0 && $('input[type="date"]')[0].type != 'date' )
  {
    //$('input[type="date"]').datepicker({ dateFormat: 'yy/mm/dd' });
    $('input[type="date"]').datepicker({ dateFormat: 'dd/mm/yy' });
    $('input[type="date"]').attr('data-format', 'dd/mm/yy');

    //change format of date input from YYYY-MM-DD to DD/MM/YYYY
    for(var i = 0; i< $('input[type="date"]').length; i++)
    {
      var input = $('input[type="date"]')[i];
      var date = $(input).val();
      if( date!='')
      {
        if(date.indexOf('-')!==-1)
        {
          var y = date.substr(0,4);
          var m = date.substr(5,2);
          var d = date.substr(8,2);

          var new_date = d+'/'+m+'/'+y;
          $(input).val(new_date);
        }
      }
    }
  }
}


function catchDateFormatOnSubmit(){
  $('form').submit(function(event)
  {

    //event.preventDefault();
    if($('input[type="date"]').length>0)
    {
      for(var i = 0; i< $('input[type="date"]').length; i++)
      {
        var input = $('input[type="date"]')[i];
        var date = $(input).val();
        if($(input).attr('data-format')=='dd/mm/yy' && date!='')
        {

          if(date.indexOf('/')!==-1){
            var y = date.substr(6,4);
            var m = date.substr(3,2);
            var d = date.substr(0,2)

            var new_date = y+'-'+m+'-'+d;
            $(input).val(new_date);
          }
          //$(this).off('submit');
          //  $(this).submit();

        }
      }
    }

  });
}

function fixIssueOnFileInputs() {
  //$('.custom-file-label').text('test 1');
  var filesInput = $('.custom-file-input');
  for (var i = 0; i < filesInput.length; i++) {
    var id = filesInput[i].id;
    var lbl = 'Choisir un fichier';
    if ($('#' + id + '[multiple="multiple"]').length > 0) {
      lbl = 'Choisir un ou plusieurs fichier(s)';
    }
    $('.custom-file-label[for="' + id + '"]').html(lbl).addClass("text-left").css("overflow", "hidden");
  }


  $(document).on('change', ':file', function() {
    var input = $(this),
    numFiles = input.get(0).files ? input.get(0).files.length : 1,
    label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
      input.trigger('fileselect', [numFiles, label]);
    });


    $(':file').on('fileselect', function(event, numFiles, label) {

      var id = this.id;
      if (numFiles > 1) {
        $('.custom-file-label[for="' + id + '"]').text(numFiles + ' files');
      } else {
        $('.custom-file-label[for="' + id + '"]').text(label);
      }
    });

  }


  function copyToClipboard (str)  {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
    document.getSelection().rangeCount > 0        // Check if there is any content selected previously
    ? document.getSelection().getRangeAt(0)     // Store selection if found
    : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
  }

  function toggleExpend(){

    $('.toggleExpend').click(function(){
      var id = $(this).data('id');
      $('#'+id).toggleClass('expend');
    });
  }

  function addScrollListener (htmlElement){
    if(!htmlElement){
      htmlElement = '.js-scrollTo';
    }
    $(htmlElement).on('click', function(event) {
      var link = $(this).attr('href');
      var component = link.substring(link.lastIndexOf('#'));
      if($(component).length>0){
        event.preventDefault();
        scrollTo(component);
      }
    });
  }

  function scrollTo(htmlId){
    var speed = 500;
    var navHeight = 50;
    if($('.fixed-top').length>0){
      navHeight +=parseInt($('.fixed-top').height());
    }
    $('html, body').animate( { scrollTop: ($(htmlId).offset().top - navHeight) }, speed );
  }

  function ajax(formId, actions, tagError, locker){

    var params = {
      type: $(formId).attr('method'),
      url: $(formId).attr('action'),
      data: $(formId).serialize()
    };

    /* The first part of this condition (if enctype is an attribute of the form sent to the ajax function) should make sure that all forms using serialized data should never be affected -- Only forms using enctype (which should only happen if there is an input type file for uploading) should be able to trigger that condition. The second part of the condition should be easy to remove, as it's only a restriction because the contact form using AJAX inside the mission editor is the first (and only as of writing this comment) form using this.
    
    Ludo */

    if($(formId).attr('enctype') && formId === "#contact") {
      params.data = new FormData($(formId)[0]);
      params['contentType'] = false;
      params['processData'] = false;
    }

    $.ajax(params)
    .then(function (data) {
      if (typeof data.message !== 'undefined') {
        //alert(data.message);
        flashMessageInfo.push(data.message);
        readFlashMessage();
      }

      if(actions && actions.update!=undefined){
        if(actions.position=='bottom'){
          $('#'+actions.update).html($('#'+actions.update).html()+data);
        }
        //OTHER POSITION MUST BE DONE
        if(actions.position=='replace'){
          $('#'+actions.update).replaceWith(data);
        }
        if(actions.position=='inside'){
          $('#'+actions.update).html(data);
        }
      }

      if(actions && actions.success!=undefined){
        actions.success(data);
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
      flashMessageError.push(errorThrown);
      readFlashMessage();

      if(actions && actions.failure!=undefined){
        actions.failure(jqXHR.responseText);
      }


    });
  }

  function  initAjaxForm()
  {
    //use ajax on all form with class "ajaxForm"
    $('body').on('submit', '.ajaxForm', function (e) {
      e.preventDefault();
      ajax(this);
    });
  }

  function initConfirmationForm(){

    var confirHandler = function(e) {
      var txt = $(this).attr('data-confirm');
      var confirm_type = $(this).attr('data-confirm-type');
      if(!confirm_type){
        confirm_type = 'warning';
      }
      e.preventDefault();

      swal.fire({
        title: txt,
        //text: "Il ne sera pas possible de revenir en arrière",
        type: confirm_type,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        //confirmButtonText: "Oui, supprimer l'image"
        reverseButtons: true,
      })
      .then( (result) => {

        if (result.value) {
          $(this).off('submit');
          $(this).submit();

          $(this).off('click');
          $(this).click();
          var url  = $(this).attr('href');
          if(url){
            window.location = url;
          }

        }

      });
    }

    $('form[data-confirm]').on('submit', confirHandler);
    $('a[data-confirm]').on('click', confirHandler);
    $('button[data-confirm]').on('click', confirHandler);

  }

  function initToolTips(){
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }



  function toggleCheckbox(){

    //init ui
    var checkbox = $('.form-check input[type="checkbox"]');
    var checkboxCount = checkbox.length;
    for(var i = 0; i<checkboxCount; i ++ ){


      if($(checkbox[i]).prop('checked')){

        var label = 'label[for="'+$(checkbox[i]).attr('id')+'"]';
        $(label).addClass('active');
        //$(checkbox[i]).addClass('')
      }
    }
    //add listener to UI
    $('.form-check label').click(function(){
      $(this).toggleClass('active');
      //$('#toggleTopMenuBtn').toggleClass('open');


    });
  }

  function initCkeditor(){
  //  return;

    if(typeof(CKEDITOR_BASEPATH) == "undefined"){

      var CKEDITOR_BASEPATH = '/build/ckeditor/';
      window.CKEDITOR_BASEPATH = '/build/ckeditor/';

    }
    // #1 init style
    if(!CKEDITOR.stylesSet.get('default')){
      CKEDITOR.stylesSet.add( 'default', [
        /* Block styles */

        // These styles are already available in the "Format" drop-down list ("format" plugin),
        // so they are not needed here by default. You may enable them to avoid
        // placing the "Format" combo in the toolbar, maintaining the same features.
        /*
        { name: 'Paragraph',		element: 'p' },
        { name: 'Heading 1',		element: 'h1' },
        { name: 'Heading 2',		element: 'h2' },
        { name: 'Heading 3',		element: 'h3' },
        { name: 'Heading 4',		element: 'h4' },
        { name: 'Heading 5',		element: 'h5' },
        { name: 'Heading 6',		element: 'h6' },
        { name: 'Preformatted Text',element: 'pre' },
        { name: 'Address',			element: 'address' },
        */

        { name: 'Italic Title',		element: 'h2', styles: { 'font-style': 'italic' } },
        { name: 'Subtitle',			element: 'h3', styles: { 'color': '#aaa', 'font-style': 'italic' } },
        {
          name: 'Special Container',
          element: 'div',
          styles: {
            padding: '5px 10px',
            background: 'red',
            border: '1px solid #ccc'
          }
        },

        /* Inline styles */

        // These are core styles available as toolbar buttons. You may opt enabling
        // some of them in the Styles drop-down list, removing them from the toolbar.
        // (This requires the "stylescombo" plugin.)
        /*
        { name: 'Strong',			element: 'strong', overrides: 'b' },
        { name: 'Emphasis',			element: 'em'	, overrides: 'i' },
        { name: 'Underline',		element: 'u' },
        { name: 'Strikethrough',	element: 'strike' },
        { name: 'Subscript',		element: 'sub' },
        { name: 'Superscript',		element: 'sup' },
        */

        { name: 'Marker',			element: 'span', attributes: { 'class': 'marker' } },

        { name: 'Big',				element: 'big' },
        { name: 'Small',			element: 'small' },
        { name: 'Typewriter',		element: 'tt' },

        { name: 'Computer Code',	element: 'code' },
        { name: 'Keyboard Phrase',	element: 'kbd' },
        { name: 'Sample Text',		element: 'samp' },
        { name: 'Variable',			element: 'var' },

        { name: 'Deleted Text',		element: 'del' },
        { name: 'Inserted Text',	element: 'ins' },

        { name: 'Cited Work',		element: 'cite' },
        { name: 'Inline Quotation',	element: 'q' },

        //	{ name: 'Language: RTL',	element: 'span', attributes: { 'dir': 'rtl' } },
        //	{ name: 'Language: LTR',	element: 'span', attributes: { 'dir': 'ltr' } },

        /* Object styles */
        {
          name: 'Responsive Image',
          element: 'img',
          attributes: { 'class': 'img-fluid' }
        },
        {
          name: 'Styled Image (left test)',
          element: 'img',
          attributes: { 'class': 'left' }
        },

        {
          name: 'Styled Image (right)',
          element: 'img',
          attributes: { 'class': 'right' }
        },

        {
          name: 'Compact Table',
          element: 'table',
          attributes: {
            cellpadding: '5',
            cellspacing: '0',
            border: '1',
            bordercolor: '#ccc'
          },
          styles: {
            'border-collapse': 'collapse'
          }
        },
        /* #*/
        { name: 'Borderless Table',		element: 'table',	styles: { 'border-style': 'hidden', 'background-color': '#B3B7FF' } },
        { name: 'Square Bulleted List',	element: 'ul',		styles: { 'list-style-type': 'square' } },

        /* Widget styles */

        { name: 'Clean Image', type: 'widget', widget: 'image', attributes: { 'class': 'image-clean' } },
        { name: 'Grayscale Image', type: 'widget', widget: 'image', attributes: { 'class': 'image-grayscale' } },

        { name: 'Featured Snippet', type: 'widget', widget: 'codeSnippet', attributes: { 'class': 'code-featured' } },

        { name: 'Featured Formula', type: 'widget', widget: 'mathjax', attributes: { 'class': 'math-featured' } },

        { name: '240p', type: 'widget', widget: 'embedSemantic', attributes: { 'class': 'embed-240p' }, group: 'size' },
        { name: '360p', type: 'widget', widget: 'embedSemantic', attributes: { 'class': 'embed-360p' }, group: 'size' },
        { name: '480p', type: 'widget', widget: 'embedSemantic', attributes: { 'class': 'embed-480p' }, group: 'size' },
        { name: '720p', type: 'widget', widget: 'embedSemantic', attributes: { 'class': 'embed-720p' }, group: 'size' },
        { name: '1080p', type: 'widget', widget: 'embedSemantic', attributes: { 'class': 'embed-1080p' }, group: 'size' },

        // Adding space after the style name is an intended workaround. For now, there
        // is no option to create two styles with the same name for different widget types. See https://dev.ckeditor.com/ticket/16664.
        { name: '240p ', type: 'widget', widget: 'embed', attributes: { 'class': 'embed-240p' }, group: 'size' },
        { name: '360p ', type: 'widget', widget: 'embed', attributes: { 'class': 'embed-360p' }, group: 'size' },
        { name: '480p ', type: 'widget', widget: 'embed', attributes: { 'class': 'embed-480p' }, group: 'size' },
        { name: '720p ', type: 'widget', widget: 'embed', attributes: { 'class': 'embed-720p' }, group: 'size' },
        { name: '1080p ', type: 'widget', widget: 'embed', attributes: { 'class': 'embed-1080p' }, group: 'size' }

      ] );


      //window.CKEDITOR_BASEPATH = "/build/ckeditor/";
      CKEDITOR.basePath = CKEDITOR_BASEPATH;


      //CKEDITOR.plugins.basePath = CKEDITOR_BASEPATH;


    }



  }
  function fillPopup(html){
    $('#mask').addClass('open').addClass('visible');
    $('#popup').html(html).addClass('open');


    initCkeditor();


    if($('#popup .btn-close').length>0){

      $('#mask').addClass('noMaskClose');
      $('.btn-close').bind('click', function(){
        //close popup with button
        $('#mask').removeClass('noMaskClose');
        $('#mask').click();
      });
    }

    if($('#popup button.btn-submit').length>0){
      $('#popup button.btn-submit').bind('click', function(){
        let formId = $(this).attr('name');
        if(formId!=''){
          $('#'+formId).submit();
        }
      });
    }
    else{
    }



  }

  function round(float){
    return Math.round(float*100)/100;
  }


  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
  function randomIndex(myArray){
    return getRandomInt(myArray.length);
  }

  const convertHexToRGBA = (hex, opacity) => {
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  };

  export { initCkeditor, readFlashMessage, ajax, convertHexToRGBA, initAjaxForm, initConfirmationForm, initToolTips, toggleCheckbox, addScrollListener, toggleExpend, fillPopup, round, getRandomInt, randomIndex, copyToClipboard, isMobile, fixIssueOnInputs, bindCountTextareaLimit };
